footer {
  &.border-app {
    $borderWidth: 4px;
    border: 0px;
    border-top-width: 4px;
    border-top-style: solid;
    border-image: linear-gradient(to right, #00a0fc 0%, #0a1435 100%) 27 / 4px 0
      0 0 / 0 0 0 0 round;
    border-image: -webkit-linear-gradient(left, #00a0fc 0%, #0a1435 100%) 27 /
      4px 0 0 0 / 0 0 0 0 round;
    border-image: -moz-linear-gradient(left, #00a0fc 0%, #0a1435 100%) 27 / 4px
      0 0 0 / 0 0 0 0 round;
  }
}
