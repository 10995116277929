@import "../../../styles/animator";

.preloader {
  display: flex;
  background-color: #fff;
  height: 100vh;
  width: 100%;
  transition: height 200ms linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.animation__wobble {
  -webkit-animation: wobble 1.5s;
  animation: wobble 1.5s;
}
